import React, { useContext, useState } from 'react';
import { JoinDialog } from '../Dialogs/JoinDialog/JoinDialog';
import { LeaveGroupDialog } from '../Dialogs/LeaveGroupDialog/LeaveGroupDialog';
import { WithdrawJoinRequestDialog } from '../Dialogs/WithdrawJoinRequest/WithdrawJoinRequest';
import { ActionType } from './ActionType';
import { UserExceptionBoundary } from '../../../../common/components/UserExceptionBoundary/UserExceptionBoundary';
import { useUser } from '../../../../common/context/user/useUser';
import { useGroupV2 } from '../../contexts/Group/useGroupV2';
import { UserActionsContext } from './UserActionsContext';
import { isCreateNewPostDeepLinkPresent } from '../Discussion/NewPost';
import { joined } from '@wix/social-groups-api';
import { useGroupBI } from '../hooks/useGroupBI';
import { GroupActionsContext } from '../../contexts/GroupActions/GroupActionsContext';
import { Tab } from '../../../../common/controllers/group-url';
import { UserException } from 'common/context/user/IUserContext';

interface Props {
  activeTab: Tab;
}

export const UserStatusProvider: React.FC<Props> = ({
  children,
  activeTab,
}) => {
  const { userActions, userRequestResponse } = useUser();
  const [activeDialog, setActiveDialog] = useState<ActionType | null>(() => {
    return isCreateNewPostDeepLinkPresent() ? ActionType.WRITE_POST : null;
  });

  const { groupV2, membership } = useGroupV2();
  const { changeTab } = useContext(GroupActionsContext);

  const bi = useGroupBI();

  React.useEffect(() => {
    if (
      userRequestResponse &&
      userRequestResponse.exception === UserException.JOIN_TO_GROUP_REQUIRED
    ) {
      setActiveDialog(ActionType.JOIN_DIALOG);
    }
  }, [userRequestResponse]);

  const maybeWritePost = async (origin?: string): Promise<void> => {
    if (activeTab !== Tab.DISCUSSION) {
      changeTab(Tab.DISCUSSION);
    }
    try {
      await checkMembership(origin);
      bi.groupCreatePostClick(origin || 'top_rce_area');
      setActiveDialog(ActionType.WRITE_POST);
      return;
    } catch (e) {}
  };

  function checkMembership(origin: string | undefined) {
    // has member permissions
    if (joined(membership!)) {
      return Promise.resolve();
    }
    setActiveDialog(ActionType.JOIN_DIALOG);
    return Promise.reject(
      new Error('User is not a group member or is not logged in'),
    );
  }

  const initAction = async (
    type: ActionType,
    origin?: string,
  ): Promise<void> => {
    if (type === ActionType.WRITE_POST) {
      return maybeWritePost(origin);
    }
    if (type === ActionType.CHECK_GROUP_MEMBERSHIP) {
      return checkMembership(origin);
    }
    setActiveDialog(type);
    return Promise.resolve();
  };

  const handleLeaveGroupConfirm = () => {
    userActions.leaveGroup({ groupId: groupV2!.id! });
    closeDialog();
  };
  const handleWithdrawJoinRequest = () => {
    userActions.cancelJoinGroupRequest({ groupId: groupV2!.id! });
    closeDialog();
  };

  function closeDialog() {
    if (activeDialog === ActionType.JOIN_DIALOG) {
      userActions.refuseToJoin(groupV2!.id!);
    }
    setActiveDialog(null);
  }

  return (
    <>
      <UserActionsContext.Provider
        value={{
          initAction,
          currentAction: activeDialog,
          finishAction: closeDialog,
        }}
      >
        {children}
        <LeaveGroupDialog
          isOpen={activeDialog === ActionType.LEAVE_GROUP}
          onClose={closeDialog}
          groupId={groupV2?.id!}
          groupTitle={groupV2?.title!}
          onLeaveGroup={handleLeaveGroupConfirm}
        />
        <WithdrawJoinRequestDialog
          isOpen={activeDialog === ActionType.WITHDRAW_JOIN_REQUEST}
          onClose={closeDialog}
          onWithdrawJoinRequest={handleWithdrawJoinRequest}
        />
        <JoinDialog
          isOpen={activeDialog === ActionType.JOIN_DIALOG}
          onClose={closeDialog}
        />
      </UserActionsContext.Provider>
      <UserExceptionBoundary />
    </>
  );
};

UserStatusProvider.displayName = 'UserStatusProvider';
